import UnderConstructionLayout from "../../layout/UnderConstructionLayout/UnderConstructionLayout";

const YearlyPage = () => {

    return (
        <div className="">
            <UnderConstructionLayout />
        </div>
    );
};

export default YearlyPage;