import UnderConstructionLayout from "../../layout/UnderConstructionLayout/UnderConstructionLayout";

const WeeklyPage = () => {

    return(
        <>
         <UnderConstructionLayout />
        </>
    );
};

export default WeeklyPage;